import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { TableauState } from './types';
import factory from '@/repositories/Tableau/RepositoryFactory';

const siteRepository = factory.get('site');

const actions: ActionTree<TableauState, RootState> = {
  fetchProjects({ commit, rootState }) {
    return siteRepository
      .getProjects(rootState.auth.siteId)
      .then(res => commit('ADD_PROJECTS', res.data.projects.project))
      .catch(e => console.log(e));
  },

  fetchWorkbooksByUser({ commit, rootState }) {
    const { siteId, userId } = rootState.auth;
    return siteRepository
      .getWorkbooksByUser(siteId, userId)
      .then(res => commit('ADD_WORKBOOKS', res.data.workbooks.workbook))
      .catch(e => console.log(e));
  },

  fetchWorkbookViews({ commit, rootState }, workbookId: string) {
    const { siteId } = rootState.auth;
    commit('ADD_ACTUAL_VIEWS', [])
    return siteRepository
      .getViewsByWorkbook(siteId, workbookId)
      .then(res => commit('ADD_ACTUAL_VIEWS', res.data.views.view))
      .catch(e => console.log(e));
  },

  async fetchWorkbookViewPreviewImg(
    { commit, rootState },
    { workbookId, viewId }
  ) {
    const { siteId } = rootState.auth;
    try {
      const { data } = await siteRepository.getViewPreviewImage(
        siteId,
        workbookId,
        viewId
      );
      commit('ADD_VIEW_THUMBNAIL', { id: viewId, thumbnailBlob: data });
    } catch (error) {
      console.log(error);
    }
  },

  setVizObject({ commit }, vizObject: tableau.Viz) {
    commit('SET_VIZ_OBJECT', vizObject);
  },
};

export default actions;


import Vue from 'vue';

export default Vue.extend({
  name: 'tableau-viz-component',
  props: {
    url: { type: String, default: '' },
    ticket: {type: String, default: ''},
    options: { type: Object },
    width: { type: String },
    height: { type: String },
    filters: { type: Object },
  },
  methods: {
    initViz() {
      const id='TableauViz'
      const viz = document.createElement('tableau-viz')
      viz.setAttribute('id', id);
      viz.setAttribute('src', this.url);
      viz.setAttribute('token', this.ticket)
      viz.setAttribute('toolbar', 'top')
      this.$el.appendChild(viz)
    }
  },
  watch: {
    url(newUrl) {
      const existingViz = document.getElementById('TableauViz');
      if (existingViz) {
        existingViz.remove();
      }

      this.initViz();
    },
  },
  mounted() {
    this.initViz()

    const id = 'tableau-sdk';
    let script = document.getElementById(id);
    if (!script) {
      script = document.createElement('script')
      script.setAttribute('id', id);
      script.setAttribute('src', "https://embedding.tableauusercontent.com/tableau.embedding.3.1.0.min.js");
      script.setAttribute('type', 'module')
      document.head.appendChild(script)
    }
  }
});

import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { AuthState, LoginPayload } from './types';
import factory from '@/repositories/Tableau/RepositoryFactory';

const authRepository = factory.get('auth');

const actions: ActionTree<AuthState, RootState> = {
  login({ commit }, { username, password }: LoginPayload) {
    return authRepository
      .login(username, password)
      .then(({ data }) => {
        commit('LOGIN', data.credentials);
        commit('ADD_USERNAME', username);
        window.localStorage.setItem('token', data.credentials.token);
      })
      .catch(e => console.log(e));
  },

  logout({ commit }) {
    commit('LOGOUT');
    window.localStorage.removeItem('token');
  },

  getTableauTicket({ commit, rootState }) {
    const { userId, siteId } = rootState.auth;
    return authRepository
      .getTableauTicket(userId, siteId)
      .then(({ data }) => {
        commit('ADD_TRUSTED_TICKET', data.token);
      })
      .catch(e => {
        throw new Error(e);
      });
  },
  getMe({commit, rootState}) {
    const { userId, siteId } = rootState.auth;
    return authRepository.getMe(userId, siteId)
    .then(({data}) => {
      commit('ADD_USER_DATA', data.user);
    })
    .catch(e => console.log(e));
  }
};

export default actions;

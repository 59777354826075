import { Module } from 'vuex';
import { RootState } from '../types';
import { TableauState } from './types';
import state from '@/store/tableau/state';
import getters from '@/store/tableau/getters';
import mutations from '@/store/tableau/mutations';
import actions from '@/store/tableau/actions';

const tableau: Module<TableauState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default tableau;

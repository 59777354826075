
import Vue from 'vue';
import TableauVizComponent from '@/components/TableauViz/TableauVizComponent.vue';
import { mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'ReportPage',
  components: {
    TableauVizComponent,
  },
  computed: {
    ...mapState('auth', ['trustedTicket']),
    ...mapGetters('tableau', ['getWorkbookNameById', 'getViewNameById']),
    contentUrl(): string {
      const contentUrl = this.$route.params.contentUrl;
      return contentUrl.replace('sheets/', '');
    },
    url(): string {
      return `${process.env.VUE_APP_TABLEAU_API}/t/${process.env.VUE_APP_CONTENT_URL}/views/${this.contentUrl}`;
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
});

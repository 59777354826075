import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/auth';
import global from '@/store/global';
import tableau from '@/store/tableau';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    global,
    tableau,
  },
});

import { GetterTree } from 'vuex';
import { RootState } from '../types';
import { TableauState } from './types';

const getters: GetterTree<TableauState, RootState> = {
  getWorkbooksByProjectId: state => (projectId: string) =>
    state.workbooks.filter(workbook => workbook.project.id === projectId),

  getProjectNameById: state => (projectId: string) =>
    state.projects.find(p => p.id === projectId)?.name,

  getWorkbookNameById: state => (workbookId: string) =>
    state.workbooks.find(w => w.id === workbookId)?.name,

  getViewNameById: state => (viewId: string) =>
    state.actualViews.find(v => v.id === viewId)?.name,

  getViewThumbnailObjUrlById: state => (viewId: string) => {
    const vThumbnail = state.viewsThumbnails.find(v => v.id === viewId);
    return vThumbnail ? URL.createObjectURL(vThumbnail.thumbnailBlob) : '';
  },

  getFirstProjectId: state => state.projects[0].id,

  getFirstWorkbookId: state => state.workbooks[0].id,
};

export default getters;

import { AuthState } from './types';

const state: AuthState = {
  isAuthenticated: false,
  siteId: '',
  userId: '',
  username: '',
  token: '',
  trustedTicket: '',
  name: '',
  fullName: '',
  siteRole: '',
  lastLogin: '',
};

export default state;

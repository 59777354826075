import state from '@/store/global/state.ts';
import getters from '@/store/global/getters.ts';
import mutations from '@/store/global/mutations.ts';
import actions from '@/store/global/actions.ts';
import { Module } from 'vuex';
import { GlobalState } from './types';
import { RootState } from '../types';

const global: Module<GlobalState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default global;

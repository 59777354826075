import axios from 'axios';

const baseDomain = process.env.VUE_APP_BASE_URL;
const baseURL = `${baseDomain}/api/3.18`; // Incase of /api/v1;
// TODO add version to env

// ALL DEFUALT CONFIGURATION HERE

const axiosInstance = axios.create({
  baseURL,
});

export default axiosInstance;

import { SignInCredentials, User } from '@/repositories/Tableau/AuthRepository/types';
import { MutationTree } from 'vuex';
import { AuthState } from './types';

const mutations: MutationTree<AuthState> = {
  LOGIN(state, { site, user, token }: SignInCredentials) {
    state.isAuthenticated = true;
    state.siteId = site.id;
    state.userId = user.id;
    state.token = token;
  },
  LOGOUT(state) {
    state.isAuthenticated = false;
    state.token = '';
  },
  ADD_USERNAME(state, username: string) {
    state.username = username;
  },
  ADD_USER_DATA(state, {name, fullName, lastLogin, siteRole}: User) {
    state.fullName = fullName;
    state.name = name;
    state.lastLogin = lastLogin;
    state.siteRole = siteRole
  },
  ADD_TRUSTED_TICKET(state, ticket: string) {
    state.trustedTicket = ticket;
  },
};

export default mutations;

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#8e016d', // navbar, top bar, active link
        secondary: '#00BCF8', // drawer, bottom bar in cards
        accent: '#efeff0', //
        button: '#8e016d', // login button
        background: '#FFFFFF', // background color
      },
    },
    options: { customProperties: true },
  },
});

import axiosInstance from '@/repositories/Tableau/Repository';
import { AuthRepository } from '@/repositories/Tableau/types';
import { PostSignInPayload } from './types';

// https://help.tableau.com/current/api/rest_api/en-us/REST/rest_api_ref.htm#sign_in

const authRepository: AuthRepository = {
  resource: '/auth',

  login(username, password) {
    const payload: PostSignInPayload = {
      credentials: {
        name: username,
        password: password,
        site: {
          contentUrl: `${process.env.VUE_APP_CONTENT_URL}`,
        },
      },
    };
    return axiosInstance.post(`${this.resource}/signin`, payload);
  },

  getTableauTicket(userId, siteId) {
    const host = process.env.VUE_APP_TRUSTED_URL;
    return axiosInstance.get(`${host}/sites/${siteId}/users/${userId}/token`);
  },

  getMe(userId, siteId) {
    return axiosInstance.get(`sites/${siteId}/users/${userId}`);
  }
};

export default authRepository;


import Vue from 'vue';
import ReportThumbnail from '@/components/ReportThumbnail/ReportThumbnail.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'WorkbookPage',
  components: {
    ReportThumbnail,
  },
  computed: {
    ...mapState('tableau', ['actualViews']),
    ...mapGetters('tableau', ['getWorkbookNameById']),
    projectId(): string {
      return this.$route.params.projectId;
    },
    workbookId(): string {
      return this.$route.params.workbookId;
    },
  },
  methods: {
    ...mapActions('tableau', ['fetchWorkbookViews']),
    ...mapActions('auth', ['getTableauTicket']),
    async goToReport(viewId: string, contentUrl: string) {
      try {
        await this.getTableauTicket();
        this.$router.push({
          name: 'Report',
          params: {
            contentUrl,
            projectId: this.projectId,
            workbookId: this.workbookId,
            viewId,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  watch: {
    workbookId: function(newWorkBookId: string) {
      this.fetchWorkbookViews(newWorkBookId);
    },
  },
  created() {
    this.fetchWorkbookViews(this.workbookId);
  },
});

import { AxiosInstance } from 'axios';
import { Dispatch, Store } from 'vuex';
import { RootState } from '@/store/types';

export const pending = (reqCounter: number, dispatch: Dispatch): number => {
  const counter = reqCounter + 1;
  if (counter === 1) {
    dispatch('global/showSpinner');
  }
  return counter;
};

export const done = (reqCounter: number, dispatch: Dispatch): number => {
  const counter = reqCounter - 1;
  if (counter === 0) {
    dispatch('global/hideSpinner');
  }
  return counter;
};

function bindInterceptor(
  axiosInstance: AxiosInstance,
  { dispatch }: Store<RootState>
) {
  let requestsPending = 0;

  // Add a request interceptor
  axiosInstance.interceptors.request.use(
    function(config) {
      requestsPending = pending(requestsPending, dispatch);
      const token = window.localStorage.getItem('token');
      if (token) {
        config.headers['X-Tableau-Auth'] = token;
      }

      return config;
    },
    function(error) {
      requestsPending = done(requestsPending, dispatch);
      dispatch('global/showError', error.request.message);
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      requestsPending = done(requestsPending, dispatch);
      return response;
    },
    function(error) {
      requestsPending = done(requestsPending, dispatch);
      const authErrors = [401, 403];
      if (error.response.status in authErrors) {
        dispatch('auth/logout');
      }
      dispatch('global/showError', error.response.statusText);

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

export default bindInterceptor;

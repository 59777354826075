import { MutationTree } from 'vuex';
import { GlobalState } from './types';

const mutations: MutationTree<GlobalState> = {
  ADD_ERROR(state, err) {
    state.errors.push(err);
  },
  REMOVE_ERROR(state) {
    state.errors.pop();
  },
  SHOW_SPINNER(state) {
    state.loading = true;
  },
  HIDE_SPINNER(state) {
    state.loading = false;
  },
};

export default mutations;

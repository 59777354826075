import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import WorkbookPage from '@/views/Workbook/index.vue';
import ReportPage from '@/views/Report/index.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login/index.vue'),
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/isLogged']) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Home/index.vue'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: 'Workbook',
        path: '/project/:projectId/workbook/:workbookId',
        component: WorkbookPage,
      },
      {
        name: 'Report',
        path: '/project/:projectId/workbook/:workbookId/view/:viewId',
        component: ReportPage,
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // catch page if required authorization
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['auth/isLogged']) {
      next();
      return;
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;

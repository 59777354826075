import { ActionTree } from 'vuex';
import { RootState } from '../types';
import { GlobalState } from './types';
import { AxiosError } from 'axios';

const actions: ActionTree<GlobalState, RootState> = {
  showError({ commit }, error: AxiosError) {
    commit('ADD_ERROR', error);
  },

  closeError({ commit }) {
    commit('REMOVE_ERROR');
  },

  showSpinner({ commit }) {
    commit('SHOW_SPINNER');
  },

  hideSpinner({ commit }) {
    commit('HIDE_SPINNER');
  },
};

export default actions;

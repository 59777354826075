import {
  GetProjectBySiteProject,
  GetViewsByWorkbookView,
  GetWorkbooksByUserWorkbook,
} from '@/repositories/Tableau/SiteRepository/types';
import { MutationTree } from 'vuex';
import { TableauState, ViewThumbnail } from './types';

const mutations: MutationTree<TableauState> = {
  ADD_PROJECTS(state, payload: GetProjectBySiteProject[]) {
    state.projects = payload;
  },
  ADD_WORKBOOKS(state, payload: GetWorkbooksByUserWorkbook[]) {
    state.workbooks = payload;
  },
  ADD_ACTUAL_VIEWS(state, payload: GetViewsByWorkbookView[]) {
    state.actualViews = [...payload];
  },
  ADD_VIEW_THUMBNAIL(state, payload: ViewThumbnail) {
    state.viewsThumbnails = [...state.viewsThumbnails, payload];
  },
  SET_VIZ_OBJECT(state, payload: tableau.Viz) {
    state.vizObject = payload;
  },
};

export default mutations;

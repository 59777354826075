
import Vue from 'vue';
import AppProgressSpinner from '@/components/layout/AppProgressSpinner.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: 'App',
  components: {
    AppProgressSpinner,
  },
  computed: {
    ...mapState('global', ['errors', 'loading']),
    ...mapGetters('auth', ['isLogged']),
  },
  methods: {
    ...mapActions('global', ['closeError']),

    close(): void {
      this.closeError();
    },
  },
  watch: {
    isLogged(): void {
      if (!this.isLogged) {
        this.$router.push('/login');
      }
    },
  },
});


import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
export default Vue.extend({
  name: 'ReportThumbnail',
  props: {
    name: {
      required: true,
      type: String,
    },
    workbookId: {
      required: true,
      type: String,
    },
    viewId: {
      required: true,
      type: String,
    },
    onClick: {
      required: true,
      type: Function,
    },
  },
  computed: {
    ...mapGetters('tableau', ['getViewThumbnailObjUrlById']),
    imgUrl(): string {
      return this.getViewThumbnailObjUrlById(this.viewId);
    },
    imgLoaded(): boolean {
      return !!this.getViewThumbnailObjUrlById();
    },
  },
  methods: {
    ...mapActions('tableau', ['fetchWorkbookViewPreviewImg']),
  },
  async mounted() {
    if (!this.imgUrl) {
      await this.fetchWorkbookViewPreviewImg({
        workbookId: this.workbookId,
        viewId: this.viewId,
      });
    }
  },
});

import { Repositories } from './types';
import authRepository from '@/repositories/Tableau/AuthRepository/AuthRepository';
import siteRepository from './SiteRepository/SiteRepository';

const repositories: Repositories = {
  auth: authRepository,
  site: siteRepository,
};

export default {
  get: <K extends keyof Repositories>(key: K) => repositories[key],
};

import { TableauState } from './types';

const state: TableauState = {
  projects: [],
  workbooks: [],
  actualViews: [],
  viewsThumbnails: [],
  vizObject: null,
};

export default state;

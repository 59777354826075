import state from '@/store/auth/state.ts';
import getters from '@/store/auth/getters.ts';
import mutations from '@/store/auth/mutations.ts';
import actions from '@/store/auth/actions.ts';
import { Module } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../types';

const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default auth;

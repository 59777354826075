import { SiteRepository } from '../types';
import axiosInstance from '@/repositories/Tableau/Repository';

const siteRepository: SiteRepository = {
  resource: '/sites',

  getProjects(siteId) {
    return axiosInstance.get(`${this.resource}/${siteId}/projects`);
  },

  getWorkbooksByUser(siteId, userId) {
    return axiosInstance.get(
      `${this.resource}/${siteId}/users/${userId}/workbooks`
    );
  },

  getViewsByWorkbook(siteId, workbookId) {
    return axiosInstance.get(
      `${this.resource}/${siteId}/workbooks/${workbookId}/views`
    );
  },

  getViewPreviewImage(siteId, workbookId, viewId) {
    return axiosInstance.get(
      `${this.resource}/${siteId}/workbooks/${workbookId}/views/${viewId}/previewImage`,
      { responseType: 'blob' } // VERY IMPORTANT!!!
    );
  },
};

export default siteRepository;

import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import bindInterceptor from '@/repositories/axiosInterceptor';
import axiosInstance from './repositories/Tableau/Repository';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created: () => bindInterceptor(axiosInstance, store),
  render: h => h(App),
}).$mount('#app');
